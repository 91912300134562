import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["query", "hiddenField", "searchResults"]
  static values = {
    url: String,
    hiddenField: String,
  }


  connect() {
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.hideResults();
      }
    });
  }

  async search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      try {
        const response = await fetch(`${this.urlValue}?search=${encodeURIComponent(this.queryTarget.value)}`);
        const text = await response.text();
        Turbo.renderStreamMessage(text);
      } catch (error) {
        console.error('An error occurred while fetching search results:', error);
      }
    }, 333);
  }

  result_selected(event) {
    this.searchResultsTarget.innerHTML = "";
    this.queryTarget.value = event.params.name;
    this.hiddenFieldTarget.value = event.params.id
    this.queryTarget.focus()
  }

  hideResults() {
    this.searchResultsTarget.innerHTML = "";
  }
}
