import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["spinner", "input", "form"];

    connect() {
        this.timeout = null;
        this.duration = 1000;
        this.waiting = false;

        this.inputTarget.addEventListener("input", (_event) => {
            this.resizeTextarea();
        });
        this.inputTarget.addEventListener("focus", (_event) => {
            this.resizeTextarea();
        });

        document.addEventListener("turbo:frame-load", () => this.resizeTextarea());
    }

    resizeTextarea() {
        this.inputTarget.style.height = "";
        this.inputTarget.style.height = Math.max(this.inputTarget.scrollHeight, 80) + "px";
    }
    save() {
        if (this.waiting == false) {
            this.showSpinner();
            this.waiting = true;

            this.timeout = setTimeout(() => {
                this.formTarget.requestSubmit();
                clearTimeout(this.timeout);
                this.waiting = false;
                this.hideSpinner();
            }, this.duration);
        }
    }

    showSpinner() {
        if (this.hasSpinnerTarget == false) {
            return;
        }
        console.log(this.spinnerTarget);
        this.spinnerTarget.classList.remove("invisible");
        this.spinnerTarget.classList.add("visible");
    }

    hideSpinner() {
        if (this.hasSpinnerTarget == false) {
            return;
        }
        console.log(this.spinnerTarget);
        this.spinnerTarget.classList.add("invisible");
        this.spinnerTarget.classList.remove("visible");
    }
}
