import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {url: String, wait: Number};

  connect() {
    console.log("ipos controller connecting...");
    this.submit();
  }

  submit() {
    setTimeout(() => {
      fetch(this.urlValue)
        .then((response) => response.text())
        .then(Turbo.renderStreamMessage)
        .catch((error) => console.log(error));
    }, this.waitValue);
  }

  disconnect() {
    console.log("ipos controller disconnecting...");
  }
}
